import  Link  from 'next/link'; 
import Image from 'next/image'; 
export default function ProductCompareBar({products, clearAll, prodRemove, typeId}) {
  const compareLink = ()=> {
    return `/lex-cms/product-comparison/${typeId}?` + products.map((d, i) => ( `ids=${d.id}`) ).join('&') 
  }
  const slots = [0, 1, 2, 3]
  let prodSlots = []
   for (let i in slots) { 
      prodSlots[i]  = products[i] 
  }

  return (
    <>
      <div
        data-testid="product-comparisons"
        className="lexcms-product-comparisons"
      >
        {prodSlots.map((product, i) => {
          return product ? (
            <div
              key={i}
              id={`compare-product-${product.id}`}
              className="lexcms-product-compare-slot"
            >
              <button onClick={() => {   prodRemove(product)} }>X</button>
              <Image
                priority
                src={product.image}
                height={65}
                width={65}
                alt={""}
                className="lexcms-product-compare-thumbnail"
              />
              <span>{product.name}</span>
            </div>
          ) : (
            <div key={i} className="lexcms-product-compare-slot"></div>
          );
        })}
        {products && products.length > 1 && (
          <Link href={compareLink()}>
            <a className="lexcms-product-compare-trigger-compare-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1441.9 895.3">
                <g fill="#ffffff">
                  <path d="m754 516q-54 0-105-32t-80-66t-83-104q-48-62-75-94t-78-77t-107-66t-122-21l-104 0l0 140l104 0q54 0 106 32t81 66t83 104q62 82 101 126t116 88t163 44l36 0l0 120l210-180l-210-180l0 100l-36 0z m-484-88q-74 78-166 78l-104 0l0 140l104 0q140 0 254-108q-14-16-37-45t-27-33q-8-12-24-32z m520-242l0 100l210-180l-210-180l0 120l-36 0q-140 0-260 116q46 58 72 92q0 2 6 9t8 11q84-88 174-88l36 0z" />
                </g>
              </svg>
              Compare
            </a>
          </Link>
        )}
        {clearAll && (
          <button
            onClick={clearAll}
            className="lexcms-product-compare-clear-all-button"
          >
            Clear All
          </button>
        )}
      </div>
    </>
  ); 

}
