import {useReducer} from 'react'; 

export const loadFromStorage = ()=> {
  let current;
  if (window && window.localStorage){
    current  = window.localStorage.getItem('compared')
  }
  return   current ? current.split(',') : []
}
const addToStorage = (product) => {
  if (window && window.localStorage){
    let current = loadFromStorage()
    current.push(product.id);
    window.localStorage.setItem('compared', current.join(','));

  }
}
const clearStorage = () => {
  if (window && window.localStorage){
    let current = loadFromStorage()
    window.localStorage.setItem('compared', null);

  }
}


const removeFromStorage = (product) => {
  if (window && window.localStorage){
  let current = loadFromStorage()
  current =  current.filter((id )=> {
      return product.id.toString() !== id   
   })
   window.localStorage.setItem('compared', current.join(','));
  }

}


export default function compareProductReducer(state, action)   {

  switch (action.type) {
    case 'addCompareProduct':
      addToStorage(action.product)
      return [...state, action.product]
    case 'prodRemove':
      removeFromStorage(action.product)
      return  state.filter((d, i) => d.id !== action.product.id )  
    case 'filterByCurrentProducts':  
      return state.filter((d, i) => action.products.map((prod, i) => prod.id ).includes(d.id)  ) 
    case 'clearAllComparisons':
      clearStorage()
      return []  
    default: 
      throw new Error();
  }
}

