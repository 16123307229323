
export default function loadProductTypes(results) {
  let typeOptions =  [];
  try { 
    typeOptions = JSON.parse(results.productTypes).sort((a, b) => {
      if (a.label <  b.label) {
        return -1
      }
      if (a.label > b.label) {
         return 1
      }
      return 0;
    });
   }
  catch(e){
  }
  return typeOptions;
}

